import React from "react";
import { BrowserRouter } from "react-router-dom";

import Navigation from "./navigation/Navigation";

function App() {
  console.log("Render App");

  return (
    <BrowserRouter>
      <Navigation />
    </BrowserRouter>
  );
}

export default App;
