import React from "react";
import { Route, Routes } from "react-router-dom";

import Dashboard from "../screens/guest/Dashboard";

function Navigation() {
  console.log("Render Navigation");

  return (
    <Routes>
      <Route exact path="/" element={<Dashboard />} />
    </Routes>
  );
}

export default Navigation;
