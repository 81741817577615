import React from "react";
import { HashLoader } from "react-spinners";

import Colors from "../constants/Colors";

export default function LoadingScreen() {
  return (
    <div
      style={{
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <HashLoader color={Colors.primary} size={75} />
    </div>
  );
}
