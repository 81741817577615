import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import axios from "axios";

import { Row } from "react-bootstrap";

import GoogleMapReact from "google-map-react";
import Chart from "react-apexcharts";

import LoadingScreen from "../../components/LoadingScreen";
import ErrorScreen from "../../components/ErrorScreen";

// https://www.npmjs.com/package/@react-google-maps/api
// https://sudolabs.com/insights/react-google-maps-geodesic-polylines-polygons
// https://codesandbox.io/p/sandbox/reactgooglemapsapi-editing-a-polygon-popr2?file=%2Fsrc%2Findex.js%3A16%2C49

const getMapOptions = (maps) => {
  return {
    streetViewControl: false,
    scaleControl: true,
    fullscreenControl: false,
    styles: [
      {
        featureType: "poi.business",
        elementType: "labels",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
    ],
    gestureHandling: "greedy",
    disableDoubleClickZoom: true,
    minZoom: 11,
    maxZoom: 22,
    mapTypeControl: true,
    mapTypeId: maps.MapTypeId.SATELLITE,
    mapTypeControlOptions: {
      style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
      position: maps.ControlPosition.BOTTOM_CENTER,
      mapTypeIds: [maps.MapTypeId.ROADMAP, maps.MapTypeId.SATELLITE],
    },
    zoomControl: true,
    clickableIcons: false,
  };
};

const getChartOptions = (
  title,
  yaxisTitle,
  stepSize,
  noFixed,
  historyHours
) => {
  return {
    chart: {
      type: "area",
      stacked: false,
      height: 350,
      zoom: {
        type: "x",
        enabled: true,
        autoScaleYaxis: true,
      },
      toolbar: {
        tools: {
          download: false,
        },
        autoSelected: "zoom",
      },
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 1,
    },
    title: {
      text: title,
      align: "left",
    },
    yaxis: {
      labels: {
        formatter: (val) => {
          return val.toFixed(noFixed);
        },
      },
      title: {
        text: yaxisTitle,
      },
      stepSize: stepSize,
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          inverseColors: false,
          opacityFrom: 0.5,
          opacityTo: 0,
          stops: [0, 90, 100],
        },
      },
    },
    xaxis: {
      type: "datetime",
      max: new Date().getTime(),
      min: new Date().getTime() - historyHours * 60 * 60 * 1000,
    },
    tooltip: {
      shared: false,
      x: {
        show: true,
        format: "dd MMM, HH:mm:ss",
      },
    },
  };
};

function Dashboard() {
  console.log("Render Dashboard");

  const [searchParams] = useSearchParams();

  const searchDeviceId = searchParams.get("deviceId");
  const searchHistoryHours = searchParams.get("historyHours");

  const deviceId = searchDeviceId ? searchDeviceId : "659dcf221f1f2d6efbae51fa";
  const historyHours = searchHistoryHours ? searchHistoryHours : 24;

  const [data, setData] = useState();
  const [error, setError] = useState();

  const loadData = () => {
    console.log("Dashboard loadData");
    axios
      .get(
        "visualization?deviceId=" + deviceId + "&historyHours=" + historyHours
      )
      .then((res) => setData(res.data))
      .catch((error) => setError(error));
  };

  useEffect(loadData, [deviceId, historyHours]);

  useEffect(() => {
    const interval = setInterval(() => {
      console.log("Dashboard Refresh");
      // should call loadData, but maps isnt implemented correctely yet :D
      window.location.reload();
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  if (!error && !data) {
    return <LoadingScreen />;
  }

  if (error) {
    return <ErrorScreen text={JSON.stringify(error, null, 2)} />;
  }

  const centerSum = data.pasture.vertexes.reduce(
    (accumulator, currentValue) => {
      return {
        lat: accumulator.lat + currentValue.latitude,
        lng: accumulator.lng + currentValue.longitude,
      };
    },
    { lat: 0, lng: 0 }
  );
  const center = {
    lat: centerSum.lat / data.pasture.vertexes.length,
    lng: centerSum.lng / data.pasture.vertexes.length,
  };

  const positionHistory = data.measurements.map((m) => {
    return {
      lat: m.averageLocationLat,
      lng: m.averageLocationLon,
    };
  });

  const sigSeries = [
    {
      name: "GSM signal strenght",
      data: data.measurements.map((m) => {
        return {
          x: new Date(m.timestamp).getTime(),
          y: m.signalStrength,
        };
      }),
    },
  ];

  const seriesSpeed = [
    {
      name: "Average",
      data: data.measurements.map((m) => {
        return {
          x: new Date(m.timestamp).getTime(),
          y: m.speedAverage,
        };
      }),
    },
    {
      name: "Max",
      data: data.measurements.map((m) => {
        return {
          x: new Date(m.timestamp).getTime(),
          y: m.speedMax,
        };
      }),
    },
  ];

  const handleApiLoaded = (map, maps) => {
    console.log("Dashboard handleApiLoaded");

    const meas = data.measurements[0];
    const pasture = data.pasture.vertexes.map((m) => {
      return {
        lat: m.latitude,
        lng: m.longitude,
      };
    });

    const position = {
      lat: meas.averageLocationLat,
      lng: meas.averageLocationLon,
    };

    var bermudaTriangle = new maps.Polygon({
      paths: pasture.concat(pasture[0]),
      strokeColor: "#FF474C",
      strokeOpacity: 0.8,
      strokeWeight: 3,
      fillColor: "#FF474C",
      fillOpacity: 0.15,
    });
    bermudaTriangle.setMap(map);

    new maps.Marker({
      position: position,
      map,
      title: "Hello World!",
    });
  };

  const mapConfig = {
    center: center,
    zoom: 19,
  };

  const sigChartOptions = getChartOptions(
    "GSM signal strenght",
    "dBm",
    1,
    0,
    historyHours
  );

  const speedChartOptions = getChartOptions(
    "Animal speed",
    "m/s",
    undefined,
    2,
    historyHours
  );

  return (
    <Row className="ms-0 me-0 mt-2 mb-0">
      <div style={{ height: "70vh", width: "100%" }}>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: "AIzaSyAQ1nBVLMOcF_dFQMDi3YSThtqikTwj5fQ",
            libraries: ["visualization"],
          }}
          options={getMapOptions}
          defaultCenter={mapConfig.center}
          defaultZoom={mapConfig.zoom}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
          heatmap={{
            positions: positionHistory,
            options: { radius: 30, opacity: 0.6 },
          }}
        />
      </div>
      <Row className="ms-0 me-0 mt-2 mb-0">
        <Chart
          options={speedChartOptions}
          series={seriesSpeed}
          type="area"
          height={400}
        />
      </Row>
      <Row className="ms-0 me-0 mt-2 mb-0">
        <Chart
          options={sigChartOptions}
          series={sigSeries}
          type="area"
          height={400}
        />
      </Row>
    </Row>
  );
}

export default Dashboard;
