const Colors = {
  primary: "#00C3A5", // green
  secondary: "#3D83DF", // blue
  tertiary: "#F6BC54", // yellow
  quaternary: "#EE2C63", // red
  backgroundPrimary: "#FBFAFC",
  backgroundSecondary: "#9b9b9b",
  backgroundTertiary: "#fcf8f2",
  backgroundQuaternary: "#e8e6e6",
  white: "white",
  balck: "black",
};

export default Colors;
